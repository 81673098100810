
$(document).ready(function() {
  if ( winWidth >= breakpoint['sp'] ) {
    var headHeight = 50;
  } else {
    var headHeight = 50;
  }

  var speed = 600;
  var form = $('.js-mailform');
  var position = form.offset().top - headHeight;

  form.prepend('<input type="hidden" name="javascript_action" value="true">');


  form.find('.form_item').each( function(){
    $(this).children('.i_confirm').css('display', 'none');
  });

  $.extend($.validator.messages, {
    required: '必須項目です。',
    email: 'メールアドレスの形式が正しくありません。',
    postal: '郵便番号の形式が正しくありません。',
    alphanum: '半角英数字で入力してください。',
    alpha: '半角英字で入力してください。',
    phone: '半角数字と「-」のみ使用できます。',
    equalTo: '同じ値を入力してください。',
    number: '半角数字で入力してください。',
    digits: '半角数字で入力してください。',
    url: 'URLの形式が正しくありません。',
    date: '日付の形式が正しくありません。',
    minlength: $.validator.format( "最低 {0} 文字以上で入力してください。" ),
    maxlength: $.validator.format( "最大 {0} 文字以下で入力してください。" ),
    min: $.validator.format( "{0} より大きな数にしてください。" ),
    max: $.validator.format( "{0} より小さな数にしてください。" ),
    step: '{0} の倍数を入力してください。',
    kana: '全角ひらがな、カタカナで入力してください。',
    hiragana: '全角ひらがなで入力してください。',
    katakana: '全角カタカナで入力してください。',
  });

  var methods = {
    postal: function(value, element){
      return this.optional(element) || /^[0-9]{3}-?[0-9]{4}$/.test(value);
    },
    alphanum: function(value, element){
      return this.optional(element) || /^[a-zA-Z0-9_]+$/.test(value);
    },
    alpha: function(value, element){
      return this.optional(element) || /^[a-zA-Z]+$/.test(value);
    },
    phone: function(value, element){
      return this.optional(element) || /^[0-9\-\ ]+$/.test(value);
    },
    kana: function(value, element){
      return this.optional(element) || /^[ぁ-んァ-ヶー　\ ]+$/.test(value);
    },
    hiragana: function(value, element){
      return this.optional(element) || /^[ぁ-んー　\ ]+$/.test(value);
    },
    katakana: function(value, element){
      return this.optional(element) || /^[ァ-ヶー　\ ]+$/.test(value);
    },
  };
  $.each(methods, function(key){
    $.validator.addMethod(key, this);
  });

  form.validate({
    rules : {
      'メールアドレス' : {
        email: true
      },
      '電話番号' : {
        phone: true,
        minlength: 10,
        maxlength: 14
      },
    },
    errorPlacement: function(error, element) {
      name = element.attr("name");
      if(
        name == 'xxx'
      ) {
        var str = name.replace(/\[\d*\]/g, '');
        error.appendTo($("#" +str+ "_error"));
      } else{
        error.insertAfter(element);
      }
    }
  });


  $('.js-btn_confirm').on('click', function(){

    formCheck = form.valid();

    if ( formCheck ) {
      form.find('.form_item').each( function(){

        var iInput   = $(this).children('.i_input');
        var iConfirm = $(this).children('.i_confirm');
        if ( iInput.length ) {
          if ( iInput.hasClass('i_checkbox') ) {
            var chText = '';
            iInput.find('.i_input_tag').each( function( index, element ) {
              if ( $(this).prop('checked') == true ) {
                if ( chText != '' ) { chText = chText + '、'; }
                chText = chText + sanitizing($(this).val());
                iConfirm.html( chText );
              }
            });
          } else {
            var iInputTag = iInput.find('.i_input_tag');
            if ( iInputTag.length ) {
              var iVal = iInputTag.val();
              if ( typeof iVal !== 'undifined' ) {
                iConfirm.html( sanitizing(iVal) );
              }
            }
          }
        }

        iInput.css('display', 'none');
        if ( iConfirm.hasClass('i_iblock') ) {
          iConfirm.css('display', 'inline-block');
        } else {
          iConfirm.css('display', 'block');
        }
      });
    }

    position = form.offset().top - headHeight;
    $('body,html').animate({scrollTop:position}, speed, 'swing');
    return false;
  });


  $('.js-btn_back').on('click', function(){
    form.find('.form_item').each( function(){
      var iInput   = $(this).children('.i_input');
      var iConfirm = $(this).children('.i_confirm');
      if ( iInput.hasClass('i_iblock') ) {
        iInput.css('display', 'inline-block');
      } else {
        iInput.css('display', 'block');
      }
      iConfirm.css('display', 'none');
    });


    $('body,html').animate({scrollTop:position}, speed, 'swing');
    return false;
  });

});



// サニタイズ関数
function sanitizing(str){
  var before = new Array('&','"',"'","<",">","\n","\t","\\n");
  var after = new Array('&amp;','&quot;','&rsquo;',"&lt;","&gt;","<br />"," ","<br />");
  for( var i=0; i < before.length; i++ )
    str = str.replace(new RegExp(before[i],'g'), after[i]);
  return str;
};